import { useMemo } from 'react'
import { useFetch } from '../hooks/useFetch'
import { isIdValid } from '../utils/id-utils'

export function useData(id: string, language: string) {
  const baseDomain = id.startsWith('i') ? (process.env.REACT_APP_IOS_CLOUD_FUNCTIONS_DOMAIN as string) : (process.env.REACT_APP_ANDROID_CLOUD_FUNCTIONS_DOMAIN as string)

  const dataOnIndex: (GetDataRaw & { id: string; language: string }) | undefined = useMemo(() => {
    const textContent = document.getElementById('shared_data')?.textContent
    if (!textContent) {
      return undefined
    }
    return JSON.parse(textContent)
  }, [])

  const mappedDataOnIndex: Data | undefined = useMemo(() => {
    if (dataOnIndex && dataOnIndex.id === id && dataOnIndex.language === language) {
      return mapRawData(dataOnIndex)
    } else {
      return undefined
    }
  }, [dataOnIndex, id, language])

  let response = useFetch<GetDataRaw>(`https://${baseDomain}/share-shareRequest?id=${id}&language=${language}`, { skip: !isIdValid(id) || mappedDataOnIndex !== undefined })

  if (mappedDataOnIndex) {
    return ['success', mappedDataOnIndex] as const
  }

  if (response[0] === 'success') {
    try {
      const mapped = mapRawData(response[1])
      return ['success', mapped] as const
    } catch (error) {
      return ['failed', error] as const
    }
  } else {
    return response
  }
}

function mapRawData(rawData: GetDataRaw): Data {
  const firstWorkout = rawData.workouts[0]
  const firstRoutine = rawData.routines[0]
  const firstPlan = rawData.userPlans[0]

  if (firstWorkout) {
    return {
      type: 'workout',
      value: getWorkoutFromRawData(firstWorkout.randomId, rawData),
      gender: rawData.gender ?? 'male',
    }
  } else if (firstPlan) {
    return {
      type: 'plan',
      value: getPlanFromRawData(firstPlan.randomId, rawData),
      gender: rawData.gender ?? 'male',
    }
  } else if (firstRoutine) {
    return {
      type: 'routine',
      value: getRoutineFromRawData(firstRoutine.randomId, rawData),
      gender: rawData.gender ?? 'male',
    }
  } else {
    throw Error('Cannot map data')
  }
}

function getWorkoutFromRawData(workoutId: string, rawData: GetDataRaw): Workout {
  const rawWorkout = rawData.workouts.find((e) => e.randomId === workoutId)
  if (!rawWorkout) {
    throw Error('Cannot find workout')
  }
  return {
    ...rawWorkout,
    exercises: rawData.workoutExercises
      .filter((e) => e.workoutId === rawWorkout.randomId)
      .sorted((x, y) => x.exerciseIndex - y.exerciseIndex)
      .map((e) => {
        return {
          ...e,
          sets: rawData.sets.filter((s) => s.workoutId === rawWorkout.randomId && s.exerciseIndex === e.exerciseIndex).sorted((x, y) => x.setId - y.setId),
        }
      }),
  }
}

function getRoutineFromRawData(routineId: string, rawData: GetDataRaw): Routine {
  const rawRoutine = rawData.routines.find((e) => e.randomId === routineId)
  if (!rawRoutine) {
    throw Error('Cannot find routine')
  }
  return {
    ...rawRoutine,
    exercises: rawData.routineExercises
      .filter((e) => e.routineId === rawRoutine.randomId)
      .sorted((x, y) => x.exerciseIndex - y.exerciseIndex)
      .map((e) => {
        return {
          ...e,
          sets: rawData.routineSets.filter((s) => s.routineId === routineId && s.exerciseIndex === e.exerciseIndex).sorted((x, y) => x.setId - y.setId),
        }
      }),
  }
}

function getPlanFromRawData(planId: string, rawData: GetDataRaw): Plan {
  const rawPlan = rawData.userPlans.find((e) => e.randomId === planId)
  if (!rawPlan) {
    throw Error('Cannot find plan')
  }
  return {
    ...rawPlan,
    routines: rawData.userRoutinePlans
      .filter((rp) => rp.planId === rawPlan.randomId)
      .sorted((x, y) => x.dayIndex - y.dayIndex)
      .map((e) => {
        return {
          ...e,
          routine: e.routineId ? getRoutineFromRawData(e.routineId, rawData) : null,
        }
      }),
  }
}

type Data = (
  | {
      type: 'workout'
      value: Workout
    }
  | {
      type: 'routine'
      value: Routine
    }
  | {
      type: 'plan'
      value: Plan
    }
) & { gender: Gender }

export type Workout = WorkoutRaw & {
  exercises: WorkoutExercise[]
}

export type WorkoutExercise = Omit<WorkoutExerciseRaw, 'workoutId' | 'exerciseIndex'> & {
  sets: WorkoutSet[]
}

type WorkoutSet = Omit<WorkoutSetRaw, 'workoutId' | 'exerciseId' | 'exerciseIndex' | 'setId'>

export type Routine = RoutineRaw & {
  exercises: RoutineExercise[]
}

export type RoutineExercise = Omit<RoutineExerciseRaw, 'routineId' | 'exerciseIndex'> & {
  sets: RoutineSet[]
}

type RoutineSet = Omit<RoutineSetRaw, 'routineId' | 'exerciseId' | 'exerciseIndex' | 'setId'>

export type Plan = PlanRaw & {
  routines: PlanRoutine[]
}

export type PlanRoutine = Omit<PlanRoutineRaw, 'planId' | 'routineId'> & {
  routine: Routine | null
}

export type Gender = 'male' | 'female'

interface GetDataRaw {
  workouts: WorkoutRaw[]
  workoutExercises: WorkoutExerciseRaw[]
  sets: WorkoutSetRaw[]
  routines: RoutineRaw[]
  routineExercises: RoutineExerciseRaw[]
  routineSets: RoutineSetRaw[]
  userPlans: PlanRaw[]
  userRoutinePlans: PlanRoutineRaw[]
  gender?: Gender
}

interface WorkoutRaw {
  randomId: string
  userId: string
  routineId?: string
  name: string
  source: WorkoutSource
  finished: boolean
  startTimeMs: number
  finishTimeMs: number
  isDeleted: boolean
  serverUpdatedAt?: Date
}

enum WorkoutSource {
  USER_CREATED = 'USER_CREATED',
  AI_CREATED = 'AI_CREATED',
  AI_CREATOR = 'AI_CREATOR',
}

interface WorkoutExerciseRaw extends ExerciseRaw {
  workoutId: string
}

interface WorkoutSetRaw {
  workoutId: string
  exerciseId: string
  exerciseIndex: number
  setId: number
  weightKg: number
  rep: number
  distMeter: number
  timeSecond: number
  durationMs: number
}

interface RoutineRaw {
  randomId: string
  userId: string
  name: string
  source: WorkoutSource
  isDeleted: boolean
  serverUpdatedAt?: Date
}

interface RoutineExerciseRaw extends ExerciseRaw {
  routineId: string
}

interface ExerciseRaw {
  exerciseId: string
  exerciseIndex: number
  superSetId: string
  restTime: number
  additionalData: {
    name: string
    wears: { [muscle: string]: number }
    imageId: string
    isWeight: number
    isRep: number
    isDistance: number
    isDuration: number
  }
}

interface RoutineSetRaw {
  routineId: string
  exerciseId: string
  exerciseIndex: number
  setId: number
  weightKg: number
  rep: number
  distMeter: number
  timeSecond: number
}

interface PlanRaw {
  randomId: string
  userId: string
  name: string
  source: WorkoutSource
  isDeleted: boolean
  serverUpdatedAt?: Date
}

interface PlanRoutineRaw {
  planId: string
  dayIndex: number
  routineId?: string
  selectedAsNext?: number
}
