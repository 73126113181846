export function isIdValid(id: string): boolean {
  if (id === '') {
    return false
  }
  if (id === null) {
    return false
  }
  if (id === undefined) {
    return false
  }
  const match = id.match(/^[a|i][a-zA-Z0-9_-]+=*$/gm)
  return match != null
}
